import "./App.css";
import { useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Home from "./pages/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Blogs from "./pages/Blogs";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import Career from "./pages/Career";
import OurPeople from "./pages/OurPeople";
import Member from "./pages/Member";
import Founder from "./pages/Founder";
import PracticeAreas from "./pages/PracticeAreas";
import Service from "./pages/Service";
import Terms from "./pages/Terms";

const App = () => {
  const routes = [
    "/",
    "/blog",
    "/blogs",
    "/practice-areas",
    "/career",
    "/our-people",
    "/member",
    "/founder",
    "/contact",
    "/service",
    "/privacy-policy",
  ];

  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to homepage if invalid url
    if (!routes.includes(pathname)) {
      navigate("/");
    }

    // Scroll to the top when a new page loads
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/career" element={<Career />} />
        <Route path="/our-people" element={<OurPeople />} />
        <Route path="/member" element={<Member />} />
        <Route path="/founder" element={<Founder />} />
        <Route path="/practice-areas" element={<PracticeAreas />} />
        <Route path="/service" element={<Service />} />
        <Route path="/privacy-policy" element={<Terms />} />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
