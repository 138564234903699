import React from "react";

const Appointment = () => {
  return (
    <div className="flex lg:flex-row flex-col">
      <div className="flex flex-col justify-center md:items-start items-center lg:w-2/3 w-full md:px-16 pt-16 lg:pb-16 px-10 font-montserrat-semibold">
        <h2 className="text-xl font-bold">BOOK AN APPOINTMENT WITH US</h2>
        <p className="md:text-sm text-xs text-gray-500 font-semibold mb-8 mt-5">
          Lorem ipsum dolor sit amet consectetur. Amet placerat molestie
          fringilla ipsum elementum proin. Vel aliquam pretium ipsum nibh. Id
          sed arcu ullamcorper volutpat sem nisl facilisi sit ut. Mattis commodo
          praesent egestas lacus nunc dolor quam in tristique. Eu turpis tempor
          aliquam accumsan lorem sed.
        </p>
        <p className="md:w-1/2 w-full md:text-sm text-xs text-gray-500 font-semibold mb-8 mt-2 border-[2px] border-gray-300 p-5 rounded-md">
          Concord Avilash (1st Floor), House 62, Road 11A, Dhanmondi,
          Dhaka-1209, Bangladesh
        </p>
        <p className="text-center cursor-pointer md:w-1/4 w-1/2 bg-[#D14049] hover:bg-red-500 text-white text-xs font-semibold rounded-md px-3 py-3 focus:outline-none">
          info@siaalaw.com
        </p>
      </div>
      <img
        className="rounded-md lg:w-[40%] md:p-16 p-10"
        src="/images/map.png"
        alt="map"
      />
    </div>
  );
};

export default Appointment;
