import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Appointment from "../components/Appointment";
import BlogSlider from "../components/BlogSlider";

const Home = () => {
  const [blogs, setBlogs] = useState({});
  const [members, setMembers] = useState([]);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [filter, setFilter] = useState("All");
  const [corporateServices, setCorporateServices] = useState({});
  const [litigationServices, setLitigationServices] = useState({});

  const membersListRef = useRef(null);

  const navigate = useNavigate();

  const scrollLeft = (ref) => {
    if (ref.current) {
      const itemWidth = getItemWidth(ref);
      ref.current.scrollLeft -= itemWidth;
    }
  };

  const scrollRight = (ref) => {
    if (ref.current) {
      const itemWidth = getItemWidth(ref);
      ref.current.scrollLeft += itemWidth;
    }
  };

  const getItemWidth = (ref) => {
    const card = ref.current.querySelector(".card");
    return card.offsetWidth + 16;
  };

  const truncateString = (str, num) => {
    if (str.length > num) {
      return str.substring(0, num) + "...";
    } else {
      return str;
    }
  };

  useEffect(() => {
    if (filter === "All") {
      setFilteredMembers(members);
    } else if (filter === "Partners") {
      setFilteredMembers(
        members.filter((member) => member.title.includes("Partner"))
      );
    } else if (filter === "Senior Associates") {
      setFilteredMembers(
        members.filter((member) => member.title.includes("Senior Associate"))
      );
    } else if (filter === "Associates") {
      setFilteredMembers(
        members.filter((member) => member.title === "Associate")
      );
    } else if (filter === "Consultants") {
      setFilteredMembers(
        members.filter((member) => member.title === "Consultant")
      );
    }
  }, [filter]);

  useEffect(() => {
    fetch("/members.json")
      .then((response) => response.json())
      .then((data) => setMembers(data))
      .catch((error) => console.error("Error fetching data:", error));

    fetch("/blogs.json")
      .then((response) => response.json())
      .then((data) => setBlogs(data))
      .catch((error) => console.error("Error fetching data:", error));

    fetch("/corporate-services.json")
      .then((response) => response.json())
      .then((data) => setCorporateServices(data))
      .catch((error) => console.error("Error fetching data:", error));

    fetch("/litigation-services.json")
      .then((response) => response.json())
      .then((data) => setLitigationServices(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  useEffect(() => {
    setFilteredMembers(members);
  }, [members]);

  return (
    <>
      <section className="w-full pt-32 pb-44 bg-center bg-cover bg-[url('/public/images/home.jpg')]">
        <div className="w-full mt-20 flex flex-col justify-center items-center font-bold text-center">
          <h1 className="lg:w-[35%] md:w-[40%] w-2/3 font-manrope lg:text-4xl text-3xl text-white">
            EXCELLENCE IN LEGAL SUPPORT AND SOLUTIONS
          </h1>
          <p className="md:w-[33%] w-1/2 mt-6 font-montserrat text-gray-400 text-xs tracking-wide">
            ONE OF THE OLDEST LAW FIRMS IN THE COUNTRY, SINCE 1960S
            <br />
            EXPERTS IN CORPORATE ADVISORY, TRANSACTIONS, AND LITIGATIONS.
          </p>
          <div className="mt-5 font-montserrat text-white text-sm tracking-wide space-x-4">
            <p
              onClick={() => navigate("/founder")}
              className="inline-block cursor-pointer px-7 py-3 border-2 border-red-500 rounded-md hover:bg-red-500"
            >
              Our Firm
            </p>
            <p
              onClick={() => navigate("/our-people")}
              className="inline-block cursor-pointer px-6 py-3 border-2 border-red-500 rounded-md hover:bg-red-500"
            >
              Our People
            </p>
          </div>
        </div>

        <div className="w-full mt-36 md:px-0 px-10 flex flex-row justify-between">
          <div className="md:w-[22%] w-[40%] font-montserrat-semibold text-sm md:ml-40">
            <div className="mb-14">
              <h3 className="text-red-500 text-xs tracking-wide mb-2">NEWS</h3>
              <p className="text-white mb-2">
                {Object.keys(blogs).length > 0 && blogs["1"]["title"]}
              </p>
              <p
                onClick={() =>
                  navigate("/blog", { state: { blog: blogs["1"] } })
                }
                className="cursor-pointer inline-block text-white pb-[1.5px] border-b-2 border-white"
              >
                Read more
              </p>
            </div>

            <div>
              <h3 className="text-red-500 text-xs tracking-wide mb-2">NEWS</h3>
              <p className="text-white mb-2">
                {Object.keys(blogs).length > 0 && blogs["2"]["title"]}
              </p>
              <p
                onClick={() =>
                  navigate("/blog", { state: { blog: blogs["2"] } })
                }
                className="cursor-pointer inline-block text-white pb-[1.5px] border-b-2 border-white"
              >
                Read more
              </p>
            </div>
          </div>

          <div className="md:w-[22%] w-[40%] font-montserrat-semibold text-sm md:mr-24">
            <div className="mb-14">
              <h3 className="text-red-500 text-xs tracking-wide mb-2">NEWS</h3>
              <p className="text-white mb-2">
                {Object.keys(blogs).length > 0 && blogs["3"]["title"]}
              </p>
              <p
                onClick={() =>
                  navigate("/blog", { state: { blog: blogs["3"] } })
                }
                className="cursor-pointer inline-block text-white pb-[1.5px] border-b-2 border-white"
              >
                Read more
              </p>
            </div>

            <div>
              <h3 className="text-red-500 text-xs tracking-wide mb-2">NEWS</h3>
              <p className="text-white mb-2">
                {Object.keys(blogs).length > 0 && blogs["4"]["title"]}
              </p>
              <p
                onClick={() =>
                  navigate("/blog", { state: { blog: blogs["4"] } })
                }
                className="cursor-pointer inline-block text-white pb-[1.5px] border-b-2 border-white"
              >
                Read more
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="flex flex-col items-center text-center font-montserrat mt-10 mb-10">
          <h2 className="lg:w-[40%] w-2/3 text-xl font-bold tracking-wide mb-2">
            DECADES OF EXCELLENCE IN PROVIDING CORPORATE LEGAL SERVICES
          </h2>
          <p className="lg:w-[35%] w-2/3 font-montserrat-semibold text-sm text-gray-600">
            Count on our team to lead you to success with cutting-edge legal,
            commercial and policy advice.
          </p>
        </div>

        <div className="grid md:grid-cols-3 grid-cols-2 bg-[url('/public/images/placeholder-1.png')] bg-cover">
          {Object.keys(corporateServices).map((key, index) => (
            <div
              key={index}
              className="relative bg-black/60 h-80 w-full font-montserrat-semibold text-white border-2 border-white"
            >
              <div className="text-sm text-white absolute w-full bottom-0 left-0 p-4">
                <p>{key}</p>
              </div>

              <div
                className="absolute inset-0 md:p-6 p-3 flex flex-col justify-end transition-opacity duration-300 opacity-0 hover:opacity-100 cursor-pointer"
                style={{
                  background:
                    "linear-gradient(to bottom, #b52f37 0%, #170708 85%)",
                }}
              >
                <p className="lg:text-xl text-base mb-3">{key}</p>
                <p className="w-4/5 text-xs font-montserrat text-gray-300 mb-5">
                  {truncateString(corporateServices[key].summary, 165)}
                </p>
                <p className="text-xs tracking-wide mb-2 flex items-center">
                  <p
                    onClick={() =>
                      navigate("/service", {
                        state: {
                          service: key,
                          category: "corporate",
                        },
                      })
                    }
                    className="mt-[0.5px] cursor-pointer"
                  >
                    READ MORE
                  </p>
                  <i
                    className="fa fa-angle-right text-white ml-2"
                    style={{
                      fontSize: "20px",
                    }}
                  ></i>
                </p>
              </div>
            </div>
          ))}
        </div>

        <div className="flex flex-col items-center text-center font-montserrat mt-10 mb-10">
          <h2 className="lg:w-[30%] w-2/3 text-xl font-bold tracking-wide mb-2">
            DECADES OF DEDICATED PROVISION OF LITIGATION SERVICES
          </h2>
          <p className="lg:w-[40%] w-2/3 font-montserrat-semibold text-sm text-gray-600">
            Our team of senior counsels, seasoned advocates and barristers have
            a long-standing commitment to excellence in providing litigation
            service.
          </p>
        </div>

        <div className="grid md:grid-cols-4 grid-cols-2 bg-[url('/public/images/placeholder-3.png')] lg:bg-contain bg-cover">
          {Object.keys(litigationServices).map((key, index) => (
            <div className="relative bg-black/60 h-80 w-full font-montserrat-semibold text-white border-2 border-white">
              <div className="text-sm text-white absolute w-full bottom-0 left-0 p-4">
                <p>{key}</p>
              </div>

              <div
                className="absolute inset-0 md:p-6 p-3 flex flex-col justify-end transition-opacity duration-300 opacity-0 hover:opacity-100 cursor-pointer"
                style={{
                  background:
                    "linear-gradient(to bottom, #b52f37 0%, #170708 85%)",
                }}
              >
                <p className="lg:text-xl text-base mb-3">{key}</p>
                <p className="w-4/5 text-xs font-montserrat text-gray-300 mb-5">
                  {truncateString(litigationServices[key].summary, 165)}
                </p>
                <p
                  onClick={() =>
                    navigate("/service", {
                      state: {
                        service: key,
                        category: "litigation",
                      },
                    })
                  }
                  className="text-xs tracking-wide mb-2 flex items-center"
                >
                  <p className="mt-[0.5px] cursor-pointer">READ MORE</p>
                  <i
                    className="fa fa-angle-right text-white ml-2"
                    style={{
                      fontSize: "20px",
                    }}
                  ></i>
                </p>
              </div>
            </div>
          ))}
          <div className="relative bg-black/60 h-80 w-full font-montserrat-semibold text-white border-2 border-white">
            <div className="text-sm text-white absolute w-full bottom-0 left-0 p-4">
              <p>Others</p>
            </div>

            <div
              className="absolute inset-0 md:p-6 p-3 flex flex-col justify-end transition-opacity duration-300 opacity-0 hover:opacity-100 cursor-pointer"
              style={{
                background:
                  "linear-gradient(to bottom, #b52f37 0%, #170708 85%)",
              }}
            >
              <p className="lg:text-xl text-base mb-3">Others</p>
              <p className="text-xs tracking-wide mb-2 flex items-center">
                <p
                  onClick={() => navigate("/practice-areas")}
                  className="mt-[0.5px] cursor-pointer"
                >
                  READ MORE
                </p>
                <i
                  className="fa fa-angle-right text-white ml-2"
                  style={{
                    fontSize: "20px",
                  }}
                ></i>
              </p>
            </div>
          </div>
        </div>

        <div className="bg-slate-100 py-10 font-montserrat-semibold tracking-wide">
          <div>
            <div className="mx-auto text-center mb-6">
              <h1 className="text-xl font-bold">OUR PEOPLE</h1>
            </div>

            <div className="relative flex justify-center flex-wrap gap-2 sm:space-x-4">
              {[
                "All",
                "Partners",
                "Senior Associates",
                "Associates",
                "Consultants",
              ].map((item, index) => (
                <div
                  key={index}
                  onClick={() => setFilter(item)}
                  className={
                    (filter === item
                      ? "text-gray-700 border-gray-700 "
                      : "text-gray-400 border-gray-400 ") +
                    "hover:text-gray-700 text-sm font-semibold rounded-md px-4 py-2 border-[2px] hover:border-gray-700 focus:outline-none cursor-pointer"
                  }
                >
                  {item}
                </div>
              ))}
              <div className="absolute right-6 text-2xl sm:text-3xl lg:space-x-10 space-x-2 md:block hidden">
                <i
                  onClick={() => scrollLeft(membersListRef)}
                  className="fa fa-angle-left cursor-pointer"
                ></i>
                <i
                  onClick={() => scrollRight(membersListRef)}
                  className="fa fa-angle-right cursor-pointer"
                ></i>
              </div>
            </div>

            <div
              ref={membersListRef}
              className="scroll-container filter-container flex-nowrap overflow-x-scroll scroll-smooth transition-all duration-200 ease-in flex flex-row space-x-4 mt-5 pl-5 pb-10"
            >
              {filteredMembers.map((member, index) => (
                <div
                  key={index}
                  className="card shrink-0 lg:w-1/2 w-4/5 flex md:flex-row flex-col items-center bg-white rounded-lg p-6 shadow-xl"
                >
                  <img
                    alt={member.name}
                    className="md:w-1/3 w-full md:mb-0 mb-2 bg-white border-[0.5px] rounded-xl shadow-inner"
                    src={member.image}
                  />
                  <div className="flex flex-col justify-center md:pl-6">
                    <p
                      onClick={() =>
                        navigate("/member", { state: { member: member } })
                      }
                      className="text-lg font-bold mb-2 cursor-pointer uppercase"
                    >
                      {member.name}
                    </p>
                    <p className="text-xs text-red-500 uppercase">
                      {member.title}
                    </p>
                    {member.notice && (
                      <p className="mt-2 -mb-2 text-xs text-black uppercase">
                        {member.notice}
                      </p>
                    )}
                    <p className="text-gray-500 mt-4 text-xs">
                      {truncateString(member.description, 632)}
                    </p>
                  </div>
                </div>
              ))}
            </div>

            <div className="w-full flex items-center justify-center">
              <p
                onClick={() => navigate("/our-people")}
                className="bg-slate-800 hover:bg-slate-700 text-white text-xs font-semibold rounded-md px-4 py-3 focus:outline-none cursor-pointer"
              >
                View Full Team{" "}
                <i className="fa fa-arrow-right text-white ml-2"></i>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="flex lg:flex-row flex-col">
          <div className="flex flex-col justify-center md:items-start items-center lg:w-2/3 w-full md:px-16 pt-16 lg:pb-16 px-10 font-montserrat-semibold">
            <h2 className="text-xl font-bold">ABOUT SYED ISHTIAQ AHMED</h2>
            <span className="border border-b-2 border-red-500 w-1/5 mt-3 mb-6"></span>
            <p className="md:text-sm text-xs text-gray-500 font-semibold mb-8">
              Syed Ishtiaq Ahmed was called to the Bar of the Honourable Society
              of Lincoln’s Inn in 1958 and started practising law in erstwhile
              East Pakistan, now Bangladesh, in 1960s. He was enrolled as an
              Advocate of the Appellate Division of the Supreme Court of
              Bangladesh and in recognition of his excellence in legal practice,
              Mr. Ahmed was conferred the designation of a Senior Advocate.
              Renowned as a distinguished litigator, he carved an indelible mark
              in the legal fraternity of Bangladesh, earning reverence for his
              contributions, including serving as amicus curiae in numerous
              cases throughout his illustrious career and having argued cases
              that have been reported and serves as landmark precedents. <br />
              <br />
              Mr. Ahmed assumed the role of Additional Attorney General in 1972,
              followed by his appointment as the Attorney General of Bangladesh
              in 1976. His commitment to public service extended globally when
              he became the Permanent Representative of Bangladesh to the United
              Nations in 1978. Simultaneously, he was elected President of the
              Supreme Court Bar Association, a position he held again in
              1989-1990.
            </p>
            <p
              onClick={() => navigate("/founder")}
              className="cursor-pointer md:w-1/4 w-1/3 bg-[#D14049] hover:bg-red-500 text-white text-center text-xs font-semibold rounded-md px-3 py-3 focus:outline-none"
            >
              Read More
            </p>
          </div>
          <img
            className="rounded-md lg:w-[55%] md:p-16 p-10"
            src="/images/ishtiaq.png"
          />
        </div>
      </section>

      <BlogSlider />

      <Appointment />
    </>
  );
};

export default Home;
